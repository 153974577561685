import MessageMobileListPage from "thm/ui/common/message/MessageMobileListPage";
import {APP} from "core-uda/model/Resource";
import BeaconPage from "core-mobile/ui/beacon/BeaconPage";
import ThmRequestPage from "thm/ui/umv/requestPage/ThmRequestPage";
import ThmRenderingPage from "thm/ui/umv/ThmRenderingPage";
import SendCommandPage from "core-mobile/ui/command/SendCommandPage";
import StatisticPage from "core-mobile/ui/statistic/StatisticPage";
import LeftMenu from "core-uda/ui/leftMenu/LeftMenu";
import {RIGHTS, DATA_AUTHORIZED} from "core-uda/Rights";
import InformationPopup from "core-cmp/msg/InformationPopup";
import "thm/ui/umv/ThmUmvLeftMenu.css";
import UserInfosPage from "core-uda/ui/settings/UserInfosPage"
import ResourceUI from "core-uda/ui/ResourceUI";
import ThmMetocPage from "thm/ui/umv/metoc/ThmMetocPage";
import MultiLayerPage from "core-metoc/ui/multiLayer/MultiLayerPage";

// SAR
import SarRequestPage from "core-sar/ui/sarRequest/SarRequestPage";
import SarPage from "core-sar/ui/searchPage/SarPage";
import GeoTiffPage from "core-geotiff/ui/GeoTiffPage";

/**
 * @class UmvLeftMenu
 */
let ThmUmvLeftMenu = LeftMenu.extend({
  id:         "umvLeftMenu",
  i18nPrefix: 'thm.ui.umv.ThmUmvLeftMenu.',

  // override
  init: function (config) {
    let me        = this,
        radarMode = DATA_AUTHORIZED("radarProductOil") ? "shipOilDetection" : "shipDetection";

    me._super(config);

    APP().on("centricChanged", me.proxy(me.onCentricChanged));

    me.addButtons([{
      id:      "criteria",
      icon:    "positionSearch",
      handler: function () {
        let page = ThmRequestPage.get();

        page.setAutoClose(!me.isLocked());
        page.attr("tabFloat", me.isLocked());

        ThmRequestPage.openPage();
      },
      display: function () {
        let me                = this,
            centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
        return centricAuthorized;
      }
    }, {
      id:      "sarCriteria",
      icon:    "positionSearch",
      handler: function () {
        let page = SarRequestPage.get();

        page.setAutoClose(!me.isLocked());
        page.attr("tabFloat", me.isLocked());

        SarRequestPage.openPage();
      },
      display: function () {
        let me                = this,
            centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
        return centricAuthorized;
      }
    }, {
      id:      "rendering",
      icon:    "style",
      handler: function () {
        let page = ThmRenderingPage.get();

        page.setAutoClose(!me.isLocked());
        page.attr("tabFloat", me.isLocked());

        ThmRenderingPage.openPage();
      },
      display: function () {
        let me                = this,
            centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
        return centricAuthorized;
      }
    }, {
      id:         "mobiles",
      icon:       "mobile",
      handler:    function () {
        // TrajectoryPage.openPage();
        ResourceUI.openListSelectionPage("trajectories");
      },
      authorized: function () {
        return RIGHTS('mobile.trajectoriesTab');
      },
      display:    function () {
        let me                = this,
            centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
        return APP().hasAllMobiles && centricAuthorized;
      }
    }, {
      id:         "zones",
      icon:       "zones",
      handler:    function () {
        ResourceUI.openListSelectionPage("zones");
      },
      authorized: function () {
        return DATA_AUTHORIZED('zone');
      },
      display:    function () {
        let me                = this,
            centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
        return centricAuthorized;
      }
    }, {
      id:         "commands",
      icon:       "command",
      handler:    function () {
        let page = SendCommandPage.get();

        page.setAutoClose(!me.isLocked());
        page.attr("tabFloat", me.isLocked());

        SendCommandPage.openPage();
      },
      authorized: function () {
        return DATA_AUTHORIZED('command') && RIGHTS('data.command.create') && RIGHTS('positions.sendCommandsActive');
      }
    }, {
      id:          "message",
      icon:        "mail",
      title:       me.msg("message.title"),
      tooltipIcon: 0,
      handler:     function () {
        if (!APP().user.email) {
          InformationPopup.openPage({
            titleKey: 'emailRequired',
            textKey:  'emailRequiredText',
            onClose:  function () {
              UserInfosPage.openPage();
            }
          });
          return;
        } else {
          let page = MessageMobileListPage.get();

          page.setAutoClose(!me.isLocked());
          page.attr("tabFloat", me.isLocked());

          MessageMobileListPage.openPage();
        }
      },
      authorized:  function () {
        return DATA_AUTHORIZED('message', 'beaconModel');
      },
      display:     function () {
        const me                = this,
              centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
        return centricAuthorized;
      }
    },
      {
        id:         "shipDetection",
        icon:       "vesselDetection",
        title:      me.msg(radarMode + ".title"),
        label:      me.msg(radarMode + ".label"),
        handler:    function () {
          ResourceUI.openListSelectionPage("radarProduct");
        },
        authorized: function () {
          return DATA_AUTHORIZED('radarProduct');
        },
        display:    function () {
          let me                = this,
              centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
          return centricAuthorized;
        }
      },
      {
        id:         "layers",
        icon:       "metoc",
        handler:    function () {
          let page;

          if (RIGHTS('map.metoc.isMultiModel', true)) {
            page = MultiLayerPage.get(null, {
              showMetocDate: true,
              title:         I18N.msg('core-metoc.ui.MetocPage.title')
            });
            page.setAutoClose(!me.isLocked());
            page.attr("tabFloat", me.isLocked());
            page.open({
              model: APP().multiModel.models.metoc
            });
          } else {
            page = ThmMetocPage.get(null, {
              metocModel: APP().metocModel
            });
            page.setAutoClose(!me.isLocked());
            page.attr("tabFloat", me.isLocked());
            page.open();
          }
        },
        authorized: function () {
          return RIGHTS("map.layers.metocAuthorized");
        },
        display:    function () {
          let me                = this,
              centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
          return centricAuthorized;
        }
      },
      {
        id:         "statistics",
        icon:       "chart",
        handler:    function () {
          let page = StatisticPage.get();

          page.setAutoClose(!me.isLocked());
          page.attr("tabFloat", me.isLocked());

          StatisticPage.openPage();
        },
        authorized: function () {
          return RIGHTS('umv.statistic');
        },
        display:    function () {
          let me                = this,
              centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
          return centricAuthorized;
        }
      },
      {
        id:         "geoImages",
        icon:       "layers",
        handler:    function () {
          GeoTiffPage.openPage();
        },
        authorized: function () {
          return RIGHTS('data.geoTiff.authorized') && RIGHTS('data.geoimages.accept');
        },
        display:    function () {
          const me = this;
          const centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
          return centricAuthorized;
        }
      },
      {
        id:         "sar",
        icon:       "buoy",
        handler:    function () {
          let page = SarPage.get();

          page.setAutoClose(!me.isLocked());
          page.attr("tabFloat", me.isLocked());

          SarPage.openPage();
        },
        authorized: function () {
          return DATA_AUTHORIZED('sarOperations');
        },
        display:    function () {
          const me                = this,
                centricAuthorized = APP().getCurrentCentric().isLeftMenuAvailable(me.id);
          return centricAuthorized;
        }
      }]);
  },

  initViewModel: function () {
    let me = this;
    me._super();

    me.viewModel.attr("metocModel", APP().metocModel);
    me.viewModel.attr("isCentricsHidden", !APP().isMultiCentrics());
    me.viewModel.attr("lockable", true);
  }

});

export default ThmUmvLeftMenu;
