import $ from "core-cmp/cmp-jquery";
import _ from "core-cmp/lodash";
import {canMap} from "core-cmp/can";
import ResourceUI from "core-uda/ui/ResourceUI";
import Mobile from "core-uda/model/mobile/Mobile";
import Licence from "core-uda/model/mobile/Licence";
import Beacon from "core-uda/model/beacon/Beacon";
import {MOBILE_DATA} from "core-uda/model/mobile/MobileData";
import {DATA_AUTHORIZED} from "core-uda/Rights";
import ImportMobileIdPopup from "core-mobile/ui/ImportMobileIdPopup";
import SaveAsFleetPopup from "core-mobile/ui/SaveAsFleetPopup";
import template from "thm/ui/common/mobile/ThmMobileUITpl.stache";
import ThmDateMobileCriteria from "thm/ui/common/mobile/ThmDateMobileCriteria";
import ThmQueryByCriteria from "thm/ui/common/criteria/ThmQueryByCriteria";
import ThmFilterCriteria from "thm/ui/common/criteria/ThmFilterCriteria";
import SsasVessel from "core-uda/model/ssas/SsasVessel";
import TextCriteria from "core-criteria/text/TextCriteria";
import ThmSelectByCriteria from "thm/ui/common/criteria/ThmSelectByCriteria";
import SourceCriteria from "core-criteria/source/SourceCriteria"
import UnitFormat from "core-cmp/format/UnitFormat";
import {I18N} from "core-cmp/util/I18n";
import {PREF} from "core-cmp/Preferences";
import {APP} from "core-uda/model/Resource";
import {I18N_FIELD} from "core-uda/ui/ResourceUI";
import MobileImportPopup from "thm/ui/common/mobileImport/MobileImportPopup";

/**
 * User Interface elements for Mobile
 */
let ThmMobileUI = ResourceUI.extend({

  /**
   * @override
   */
  init: function () {
    let me = this;

    me._super({
      resourceId:       Mobile.itemId,
      i18nPrefix:       "thm.ui.common.mobile.ThmMobileUI.",
      icon:             "mobile",
      template:         template,
      contextMenuId:    "menu", // UDA Menu
      loadAfterEdition: true
    })
  },

  /**
   * @override
   */
  getLabel: function (item) {
    return MOBILE_DATA.getMobileLabel(item);
  },

  /**
   * @override
   */
  getSearchFields: function () {
    return MOBILE_DATA.getMobileTemplateFields();
  },

  /**
   * @override
   */
  updateListToolbarActions: function (context) {
    let me = this;

    if (context.udaList.name !== "vesselsAsSru" && context.udaList.name !== "otherCustomersMobiles") {
      context.actions.push(...[{
        id:         "saveAsFleet",
        label:      me.msg("saveAsFleet.label"),
        icon:       "fleetAdd",
        authorized: () => DATA_AUTHORIZED("fleet.edit"),
        handler:    (ctx, button, ev) => {
          let listModel = _.find(ctx.path, (vm) => vm.tag === "field-list");

          SaveAsFleetPopup.openPage({
            mobileList: _.map(listModel.value, "id")
          });
        }
      }, {
        id:         "importIds",
        label:      me.msg("importIds.label"),
        icon:       "import",
        authorized: true,
        handler:    function (ctx, button, ev) {
          let listModel = _.find(ctx.path, (vm) => vm.tag === "field-list");

          ImportMobileIdPopup.openPage({
            addMobiles: (idList) => {
              let currentIdList = listModel.getModelValue() || [];
              listModel.updateModelValue(_.uniq(currentIdList.concat(idList)));
              return _.difference(listModel.getModelValue(), currentIdList).length;
            },
            setMobiles: (idList) => {
              listModel.updateModelValue(_.uniq(idList));
              return listModel.modelToView(idList).length;
            }
          })
        }
      }]);
    }
  },

  /**
   * @override
   */
  getColumnOptions: function () {
    let me = this;

    return _.assign(me._super(), {
      "shipowners":             {
        format:   (value) => {
          switch (_.size(value)) {
            case  0 :
              return "";
            case  1 :
              return value[0].shipownerName || "";
            default : {
              return _.map(value, (o) => o.shipownerName + " (" + o.part + "%)").join(", ")
            }
          }
        },
        dataType: 'string'
      },
      "shipownersNameList": {
        format:   (value) => {
          if (!!value && value.length) {
            return value.join(',');
          }
          return ""
        },
        dataType: 'string'
      },
      "registeredLength":       {
        format: (value) => {
          if (value != undefined && value > 0) {
            switch (PREF().othersUnits) {
              case "english":
                return UnitFormat.DISTANCE_FORMAT.to(value, undefined, "m", "ft", 2, false);
              default:
                return value.toFixed(2);
            }
          }
          return value;
        }
      },
      "monitorActivatedTarget": I18N_FIELD("uda.mobile.field.monitorActivatedTarget.options."),
      "lastApwStatus":          I18N_FIELD("uda.mobile.field.lastApwStatus.options."),
      "authorizedProcessing":   I18N_FIELD("uda.mobile.field.authorizedProcessing.options."),
      "ownership":              I18N_FIELD("uda.mobile.field.ownership.options."),
      "integrationStatus":      I18N_FIELD("uda.mobile.field.integrationStatus.options."),
      "broadcastMode":          I18N_FIELD("uda.shipowner.field.broadcastMode.options.")
    });
  },

  loadSsasForEdition: function (id) {
    let me       = this,
        deferred = new $.Deferred();

    if (RIGHTS("data.ssas")) {
      SsasVessel.loadForEdition(id).done((ssasVessel) => {
        deferred.resolve(ssasVessel);
      }).fail(() => {
        deferred.resolve(new canMap({argosTestEnabled: false, argosTestStartDate: new Date()}));
      });
    } else {
      deferred.resolve({});
    }

    return deferred;
  },

  /**
   * @override
   */
  loadForEdition: function (data) {
    let me       = this,
        deferred = new $.Deferred();

    if (!_.isNil(data.kind) && data.kind === "ssasVessel") {
      return deferred.reject()
    }

    me.loadSsasForEdition(data.id).done((ssasVessel) => {
      Mobile.loadForEdition(data.id, data).done((mobile) => {
        let args = [];
        mobile.ssasVessel = ssasVessel;

        if (DATA_AUTHORIZED("licence")) {
          args.push(Licence.refreshAll());
        }
        if (DATA_AUTHORIZED("beacon")) {
          args.push(Beacon.refreshAll());
        }
        if (args.length === 0) {
          deferred.resolve(mobile);
        }

        $.when(...args).done(() => {
          deferred.resolve(mobile);
        })
      }).fail((error) => {
        deferred.reject(error);
      });

    }).fail((error) => {
      deferred.reject(error);
    });


    return deferred;
  },

  // /**
  //  * @override
  //  */
  // loadForEdition: function (data) {
  //   let deferred = new $.Deferred();
  //
  //   Mobile.loadForEdition(data.id, data).done((mobile) => {
  //     let args = [];
  //
  //     if (DATA_AUTHORIZED("licence")) {
  //       args.push(Licence.refreshAll());
  //     }
  //     if (DATA_AUTHORIZED("beacon")) {
  //       args.push(Beacon.refreshAll());
  //     }
  //     if (args.length === 0) {
  //       deferred.resolve(mobile);
  //     }
  //
  //     $.when(...args).done(() => {
  //       deferred.resolve(mobile);
  //     })
  //   }).fail((error) => {
  //     deferred.reject(error);
  //   });
  //   return deferred;
  // },

  /**
   * @override
   */
  readWcUdaProperties: function (cmpVm) {

    // For big data mode, activate server side mobile search.
    // Mobile is the dataSource because it's contain serach static methods
    if (!APP().hasAllMobiles) {
      cmpVm.fieldConfig.minCharSearch = RIGHTS("minCharSearch");
      cmpVm.dataSource = Mobile;
    }
  },

  /**
   * @override
   */
  getUmeLeftButtons: function () {
    let me = this;
    if (!!RIGHTS('data.importMobiles') && RIGHTS('data.importMobiles').authorized && APP().hasAllMobiles) {
      return [{
        actionId:   "mobiles-import",
        title:      me.msg("import.label"),
        label:      me.msg("import.description"),
        icon:       "database",
        style:      "active",
        isDisplay:  () => {
          return !!RIGHTS('data.importMobiles') && RIGHTS('data.importMobiles').authorized
        },
        authorized: !!RIGHTS('data.importMobiles') && RIGHTS('data.importMobiles').authorized,
        handler:    () => {
          MobileImportPopup.openSingleton({});
        }
      }];
    }

    return null;

  },

  /**
   * @override
   */
  getUreCriteria: function () {
    return {
      // 'species':       {
      //   cmpClass:      ThmListCriteria,
      //   i18nPrefix:    "uda.species.",
      //   customOptions: [{
      //     value:      'speciesCats',
      //     udaName:    'speciesCats',
      //     i18nPrefix: 'uda.speciesCat.',
      //     text:       I18N.msg("uda.speciesCat.selection")
      //   }]
      // },
      // 'kind':          {
      //   cmpClass:         SelectCriteria,
      //   mandatory:        true,
      //   optionsKeyPrefix: 'uda.ersActivity.field.kind.',
      //   labelAlign:       "top"
      // },
      // 'addTripDetail': {
      //   cmpClass: BooleanCriteria,
      //   labelKey: "thm.ui.common.ers.field.addTripDetail"
      // },
      "date":             {
        udaNames:      ['from', 'to'],
        extraUdaNames: ['since', "noStatistical"],
        cmpClass:      ThmDateMobileCriteria
      },
      "statisticSources": {
        cmpClass: SourceCriteria
      },
      "name":             {
        cmpClass:         ThmQueryByCriteria,
        format:           'string',
        type:             'string',
        minLength:        3,
        validateCriteria: true
      }
    }
  },

  /**
   * @override
   */
  getUmeCriteria: function () {

    //TODO ::: Gérer les traductions
    return {
      "selectBy":       {
        cmpClass: ThmSelectByCriteria,
        options:  ['flag']
      },
      'identifier':     {
        cmpClass:         ThmQueryByCriteria,
        optionsKeyPrefix: 'uda.callSign.',
        format:           "text",
        fields:           'callSign,registry,ref',
        type:             "text",
        i18nPrefix:       "uda.mobile."
      },
      "lengthOverall":  {
        cmpClass:   ThmFilterCriteria,
        i18nPrefix: "uda.mobile.",
        operator:   "=",
        format:     "length"
      },
      "mobileTypeName": {
        cmpClass:   ThmFilterCriteria,
        i18nPrefix: "uda.mobile.",
        operator:   "*"
      },
      "name":           {
        cmpClass:   ThmFilterCriteria,
        i18nPrefix: "uda.mobile.",
        operator:   "*"
      }
    }
  },

  /**
   *  @override
   *
   * @param error
   * @param defaultKey
   * @returns {*}
   */
  getItemDeletionErrorLabel: function (error, defaultKey) {
    let me        = this,
        msgKey    = me.i18nPrefix,
        errorType = error.args[0].split(' ')[1];

    if (error.exception !== "INTERNAL_ERROR") {
      msgKey += _.camelCase(errorType);
    } else {
      msgKey = defaultKey;
    }

    return msgKey;
  },

  /**
   * @override
   *
   * @returns {boolean}
   */
  forceReportModeForManagement: function () {
    return !APP().hasAllMobiles;
  }

});

const MOBILE_UI = new ThmMobileUI();

export default ThmMobileUI;
